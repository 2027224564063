.HierarchyAssigment {
    /* overflow: auto; */
    white-space: nowrap;
    width: 350px;
    float:left;
    overflow-y: scroll;
    height: 106vh;
    margin-bottom: 4%
}

.TableViewComponent {
    float: right;
    width: 600px;
    margin-top: 25px;
}

.Mui-selected{
    color:#E20074;
}

.HeadlineTable {
    margin-left: 440px;
    margin-bottom: -50px;
    background-color: #d7dee2;
    color: #333333;
    padding: 15px;
}



.TableSection{
    height: 5px;
    width:5px;
}

.SelectedProductmaster{
    float: left;
    padding-top: 50px;
    padding-left: 90px;
    display: block;
    width: 37%;
    
}

.AvalibleProductmaster{
    float: right;
    padding-top: 50px;
    display: block;
    width: 30%;
    
}

.SelectedTable{
    overflow-y: scroll;
    height: 100vh;
}

.AvalibleTable{
    overflow-y: scroll;
    height: 100vh;
}


table.MuiTable-root.SelectedTable {
    overflow-y: scroll;
    height: 100vh
}

table.MuiTable-root.AvalibleTable {
    overflow-y: scroll;
    height: 100vh
}


td.MuiTableCell-root.MuiTableCell-body.ungerade.MuiTableCell-alignLeft {
    background: #d7dee2;
}



td.MuiTableCell-root.MuiTableCell-body.ungerade.MuiTableCell-paddingNone.MuiTableCell-sizeSmall {
    background: #d7dee2;
}


td.MuiTableCell-root.MuiTableCell-body.ungerade.MuiTableCell-paddingCheckbox.MuiTableCell-sizeSmall {
    background: #d7dee2;
}




ul.MuiCollapse-root.MuiTreeItem-group.MuiCollapse-entered {
    color:#48586A
}

.Productlist {
    float: right;
    margin-top: 1%;
    margin-bottom: 6%;
}
 

 button.btn.btn-primary-medi {
    background-color: rgb(230, 0, 126);
    -webkit-text-stroke: thin;
    color: white;
    margin-right: 10px;
}



button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
    background-color: white;
}

span.MuiButton-label {
    color: red;
    font-weight: 900;
}

button.MuiButtonBase-root.MuiIconButton-root {
    padding: 0px;
}