html,
body {
    width: 100%;
    height: 100%;
    display: flex;

}

.layoutArea {
    font-family: 'medistradapro';
}

main {
    flex-grow: 1;
}

header,
main,
footer {
    flex-shrink: 0;
}

#root {
    width: 100%
}



::-moz-selection {
    background: #e6007e;
    color: white;
}

::selection {
    background: #e6007e;
    color: white;
}

body h3 {
    font-size: 13pt;
    font-weight: bold;
}

@font-face {
    font-family: 'medistradapro';
    src: local('medistradapro.woff2'), url(./medistradapro-webfont.woff2) format('woff2');
}

a.MuiMenuItem-root:hover {
    color: unset;
}