.Treeview {
    width: 50%;
    display: flex;
}

.Checkbox {
    display: flex;
    justify-content: flex-end;
    margin-top: -50px;
}


button.btn.btn-primary-medi {
    background-color: rgb(230, 0, 126);
    -webkit-text-stroke: thin;
    color: white;
    margin-right: 10px;
}



.MuiFormControl-root {
    width: 60%;
}